import { unwrap, useMutation } from "@src/hooks/mutation";
import { $uuid } from "@src/legacy/abstract/common";
import { getUploadUrl, makeFilePublic } from "@src/resync/mutation/storage";

export const useUpload = ({
  prefix,
  fileTypes,
  onUnsupportedFile,
  onUpload,
}: {
  prefix: string;
  fileTypes: string[];
  onUnsupportedFile: () => void;
  onUpload: (url: string, file: File) => void | Promise<void>;
}) => {
  const uploadToClient = async (files: FileList | null) => {
    if (!files) {
      return;
    }
    const supportedFiles = Array.from(files).filter(
      (file) => file && fileTypes.includes(file.type)
    );

    if (supportedFiles.length === 0) {
      onUnsupportedFile();
    }

    for (const file of supportedFiles) {
      uploadToCloud(file);
    }
  };

  const cleanFileName = (name: string) => {
    return name
      .replace(/\s+/g, "_")
      .replace(/[^a-zA-Z0-9._]/g, "")
      .replace(/\.+/g, ".");
  };

  const [uploading, uploadToCloud] = useMutation(async (file: File) => {
    const path = `${prefix}/${$uuid()}_${cleanFileName(file.name) ?? ""}`;

    const url = unwrap(
      await getUploadUrl({
        path,
        type: file.type,
      })
    );

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type ?? "application/octet-stream",
        "Access-Control-Allow-Origin": "*",
      },
      body: file,
    });

    if (response.ok) {
      const url = unwrap(await makeFilePublic(path));
      await onUpload(url, file);
    } else {
      console.error(
        "Upload failed with status " + response.status,
        await response.text()
      );
      throw new Error("Upload failed with status " + response.status);
    }
  });

  return {
    uploading,
    uploadToClient,
    uploadToCloud,
  };
};
